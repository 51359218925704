import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, Upload, message, Button, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAddTipMutation } from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";

const AddTip = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);

  // const [authorName, setAuthorName] = useState("");
  // const [authorNameEN, setAuthorNameEN] = useState("");
  // const [content, setContent] = useState("");
  // const [contentEN, setContentEN] = useState("");
  // const [authorJob, setAuthorJob] = useState("");
  // const [authorJobEN, setAuthorJobEN] = useState("");
  const [file, setFile] = useState(null);
  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const [addTip, { isLoading, isError, error }] = useAddTipMutation();

  const handleAddOffer = async () => {
    if (!file) {
      message.error("Please upload the file");
      return;
    }
    // if (!authorName) {
    //   message.error("Please add the arabic author name");
    //   return;
    // }
    // if (!authorNameEN) {
    //   message.error("Please add the english author name");
    //   return;
    // }
    // if (!content) {
    //   message.error("Please add the arabic content");
    //   return;
    // }
    // if (!contentEN) {
    //   message.error("Please add the english author name");
    //   return;
    // }
    // if (!authorJob) {
    //   message.error("Please add the arabic author job");
    //   return;
    // }
    // if (!authorJobEN) {
    //   message.error("Please add the english author job");
    //   return;
    // }

    // const tipBody = {
    //   author: authorName,
    //   author_en: authorNameEN,
    //   content: content,
    //   content_en: contentEN,
    //   author_job: authorJob,
    //   author_job_en: authorJobEN,
    // };
    const formData = new FormData();

    formData.append("photo", file);

    try {
      const res = await addTip(formData);
      setFile(null);
      // setAuthorName("");
      // setAuthorNameEN("");
      // setContent("");
      // setContentEN("");
      // setAuthorJob("");
      // setAuthorJobEN("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Tip added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding Tip:", error);
      message.error("Error adding Tip. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Add Tip"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      width={400}
      footer
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          {/* <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="اسم المؤلف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />

              <Input
                placeholder="وظيفة المؤلف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={authorJob}
                onChange={(e) => setAuthorJob(e.target.value)}
              />
              <TextArea
                placeholder="الوصف"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </Col>
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="author job in english"
                value={authorNameEN}
                onChange={(e) => setAuthorNameEN(e.target.value)}
              />

              <Input
                placeholder="Job in english"
                value={authorJobEN}
                onChange={(e) => setAuthorJobEN(e.target.value)}
              />
              <TextArea
                placeholder="Content"
                value={contentEN}
                onChange={(e) => setContentEN(e.target.value)}
              />
            </Col>
          </Row> */}
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-12">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Image (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button type="primary" onClick={handleAddOffer} loading={isLoading}>
              add tip
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddTip;
