// import { Container, Row } from "react-bootstrap";
// import Loading from "../../components/utils/Loading";
// import { useGetAllArticlesQuery } from "../../redux/Service/api";
// import "./Articles.css";
// import { language } from "../../language/Language";
// import { useNavigate } from "react-router-dom";
// import ServerError from "../../components/utils/ServerError";

// const Articles = ({ inHome }) => {
//   const navigate = useNavigate();
//   const {
//     data: allArticlesData,
//     isError: isAllArticlesError,
//     isLoading: isAllArticlesLoading,
//     error: allArticlesError,
//   } = useGetAllArticlesQuery();

//   const allArticles = allArticlesData?.data ?? null;

//   console.log(allArticles);

//   if (isAllArticlesError) {
//     return <ServerError />;
//   }

//   return (
//     <section className="blog position-relative">
//       {isAllArticlesLoading && <Loading />}

//       <main>
//         <Container>
//           <Row>
//             <section className="light">
//               {allArticles?.map((article) => (
//                 <article className="postcard light blue" key={article.id}>
//                   <span className="postcard__img_link">
//                     <img
//                       alt="TitleImage"
//                       className="postcard__img"
//                       src={article.photo}
//                     />
//                   </span>
//                   <div className="postcard__text t-dark ">
//                     <h1 className="postcard__title blue">
//                       <span>{article.title}</span>
//                     </h1>
//                     <div className="postcard__bar"></div>
//                     <div className="postcard__preview-txt">
//                       {article.content.slice(0, 250).trim()}...
//                       <span
//                         className="mx-1 text-primary"
//                         style={{ cursor: "pointer" }}
//                         onClick={() => navigate(`/Articles/${article.id}`)}
//                       >
//                         show more
//                       </span>
//                     </div>
//                     <div className="">
//                       <p className="author">{article.author}</p>
//                     </div>
//                   </div>
//                 </article>
//               ))}
//             </section>
//           </Row>
//         </Container>
//       </main>
//     </section>
//   );
// };

// export default Articles;

import { images } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";

import "./Articles.css";
import { useGetAllArticlesQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { FaQuoteRight } from "react-icons/fa";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";
import { useNavigate } from "react-router-dom";

const Articles = () => {
  const navigate = useNavigate();
  const {
    data: allArticlesData,
    isError: isAllArticlesError,
    isLoading: isAllArticlesLoading,
    error: allArticlesError,
  } = useGetAllArticlesQuery();

  const allArticles = allArticlesData?.data ?? null;

  console.log(allArticles);

  if (isAllArticlesError) {
    return <ServerError />;
  }

  return (
    <section
      className="Testimonials section_padding"
      data-aos={"fade-up"}
      style={{ position: "relative" }}
    >
      <h2 className="main_heading mb-0">
        {language === "ar" ? "بعض اراء عملائنا" : "Testimonials"}
      </h2>
      {isAllArticlesLoading && <Loading />}

      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        //
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        //
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper Testimonials_cont"
      >
        {allArticles ? (
          allArticles?.map((article) => (
            <SwiperSlide
              key={article.id}
              className="test text-center flex_center flex-column position-relative r-test"
            >
              <p className="testi_text">
                <img src={article.photo} alt="" className="rounded" />
                {/* <span className="quotes">
                  <FaQuoteRight style={{ fontSize: "2rem" }} />
                </span> */}
              </p>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className="test text-center flex_center flex-column">
            <p className="test_text">
              {language === "ar"
                ? `يتأثر الناس بسهولة بالآخرين، خاصة عند التسوق
              متصل. ولهذا السبب فإن مواقع المراجعة المستقلة مثل المصري eShop،
              GoodReads، وغيرها الكثير تحظى بشعبية كبيرة.`
                : `People are easily swayed by others, especially when shopping
              online. This is why independent review sites like Elmasry-eShop,
              GoodReads, and many others are so popular.`}
            </p>
          </SwiperSlide>
        )}
      </Swiper>
    </section>
  );
};

export default Articles;
