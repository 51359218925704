import { Button } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../redux/Service/api";
import "./SignUp.css";

const SignUp = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const [userData, setUserData] = useState({
    fullname: "",
    email: "",
    phone: null,
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    fullname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [createUser, { isLoading, isError, error }] = useRegisterMutation();

  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long";
    }
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    if (!hasUppercase || !hasLowercase) {
      return "Password must contain at least one uppercase and one lowercase character";
    }
    return null;
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();

    setErrors({
      fullname: "",
      email: "",
      password: "",
      confirmPassword: "",
    });

    // Validate name
    if (!userData.fullname.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fullname: "Full Name is required",
      }));
      return;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userData.email.trim() || !emailRegex.test(userData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Enter a valid email address",
      }));
      return;
    }
    // Validate phone number
    const phoneRegex = /^01[0-9]{9}$/;

    if (!userData.phone.trim() || !phoneRegex.test(userData.phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Enter a valid phone number starting with 01",
      }));
      return;
    }

    // Validate password
    const passwordError = validatePassword(userData.password);

    if (passwordError) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: passwordError,
      }));
      return;
    }

    // Check if passwords match
    if (userData.password !== userData.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match",
      }));
      return;
    }

    try {
      const result = await createUser({
        name: userData.fullname,
        email: userData.email,
        phone: userData.phone,
        password: userData.password,
      });
      const token = result?.data?.data?.token ?? null;
      if (token) {
        localStorage.setItem("userToken", token);
        navigate("/");
      } else {
        setErrorMessage(
          result.error?.message ||
            result?.data?.data?.message ||
            "something went wrong"
        );
        console.log("err");
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };
  return (
    <section className="signup section_padding flex_center">
      <div className="signup_cont">
        <h2>Sign Up</h2>
        <p>Create your account to get full access</p>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}

        <form action="">
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            name="fullname"
            id="name"
            placeholder="Enter Full Name"
            value={userData.fullname}
            onChange={(e) =>
              setUserData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          {errors.fullname && (
            <p className="ps-2 text-danger">{errors.fullname}</p>
          )}
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter Email Address"
            value={userData.email}
            onChange={(e) =>
              setUserData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          {errors.email && <p className="ps-2 text-danger">{errors.email}</p>}
          <label htmlFor="phone">Phone</label>
          <input
            type="number"
            name="phone"
            id="phone"
            placeholder="Enter Your Phone Number"
            value={userData.phone}
            onChange={(e) =>
              setUserData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          {errors.phone && <p className="ps-2 text-danger">{errors.phone}</p>}
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter Password"
            value={userData.password}
            onChange={(e) =>
              setUserData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          {errors.password && (
            <p className="ps-2 text-danger">{errors.password}</p>
          )}
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm Password"
            value={userData.confirmPassword}
            onChange={(e) =>
              setUserData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          {errors.confirmPassword && (
            <p className="ps-2 text-danger">{errors.confirmPassword}</p>
          )}

          <div className="signEnd flex_between">
            <p className="tologin">
              Already have an account?{" "}
              <Link className="loginLink" to="/Login">
                Login
              </Link>{" "}
              here
            </p>
            <Button
              type="submit"
              className="custom_button px-4 pt-1 pb-1"
              onClick={handleSignInSubmit}
              loading={isLoading}
            >
              Sign Up
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SignUp;
