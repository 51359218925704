// www.burdastyle.com
import logo from "../assets/logo_organica.png";
import slide1 from "../assets/xslide-01.jpg";
import slide2 from "../assets/xslide-02.jpg";
import slide3 from "../assets/xslide-03.jpg";
import about from "../assets/about.jpg";
import about1 from "../assets/xabout-01.jpg";
import about2 from "../assets/xabout-02.jpg";
import blog1 from "../assets/blog1.jpg";
import blog2 from "../assets/blog2.jpg";
import blog3 from "../assets/blog3.jpg";
import blog4 from "../assets/blog4.jpg";
import xblog1 from "../assets/xblog1.jpg";
import xblog2 from "../assets/xblog2.jpg";
import xblog3 from "../assets/xblog3.jpg";
import services1 from "../assets/services1.svg";
import services2 from "../assets/services2.svg";
import services3 from "../assets/services3.svg";
import services4 from "../assets/services4.svg";
import insta2 from "../assets/insta2.jpg";
import insta3 from "../assets/insta3.jpg";
import whitefriday from "../assets/white-friday.jpg";
import testimonial1 from "../assets/testimonial1.jpg";
import testimonial2 from "../assets/testimonial2.jpg";
import testimonial3 from "../assets/testimonial3.jpg";
import cat1 from "../assets/cat01.jpg";
import cat2 from "../assets/cat02.jpg";
import cat3 from "../assets/cat03.jpg";
import trend1 from "../assets/trend1.jpg";
import trend2 from "../assets/trend2.jpg";
import trend3 from "../assets/trend3.jpg";
import trend4 from "../assets/trend4.jpg";

// product organica
import productOrganica1 from "../assets/slider1.jpg";
import productOrganica2 from "../assets/slider2.jpg";
import productOrganica3 from "../assets/slider3.jpg";
import productOrganica4 from "../assets/slider4.jpg";
// Category
import Category1 from "../assets/Category1.jpg";
import Category2 from "../assets/Category2.jpg";
import Category3 from "../assets/Category3.jpg";
import Category4 from "../assets/Category4.jpg";
import Category5 from "../assets/Category5.jpg";
import Category6 from "../assets/Category6.jpg";
// user
import user from "../assets/user.jpg";
// footer lahfa
import cash1 from "../assets/cash-1.png";
import cash2 from "../assets/cash-2.png";
import cash3 from "../assets/cash-3.jpg";
import cash4 from "../assets/cash-4.png";
import cash5 from "../assets/cash-5.png";
import cash6 from "../assets/cash-6.png";

// Whatsapp
import Whatsapp from "../assets/whatsapp.png"

export default {
  user,
  logo,
  productOrganica1,
  productOrganica2,
  productOrganica3,
  productOrganica4,
  Category1,
  Category2,
  Category3,
  Category4,
  Category5,
  Category6,
  slide1,
  slide2,
  slide3,
  about,
  about1,
  about2,
  blog1,
  blog2,
  blog3,
  blog4,
  xblog1,
  xblog2,
  xblog3,
  services1,
  services2,
  services3,
  services4,
  insta2,
  insta3,

  whitefriday,
  testimonial1,
  testimonial2,
  testimonial3,
  cat1,
  cat2,
  cat3,
  trend1,
  trend2,
  trend3,
  trend4,
  cash1,
  cash2,
  cash3,
  cash4,
  cash5,
  cash6,
  Whatsapp,
};
