import {
  Category,
  Landing,
  OurAdvantages,
  Testimonials,
  Trending,
} from "../../Sections";
import FeaturedProducts from "../../Sections/FeaturedProducts/FeaturedProducts";
import Articles from "../Articles/Articles";
import Products from "../Products/Products";

const Home = () => {
  return (
    <>
      <Landing />
      <Category />
      <Products />
      <FeaturedProducts />
      <Trending />
      {/* <Testimonials /> */}
      <Articles inHome={true} />
      <Testimonials />
      <OurAdvantages />
    </>
  );
};

export default Home;
