import { Button, message } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/utils/Loading";
import {
  useGetLogedUserQuery,
  useUpdateUserDataMutation,
} from "../../redux/Service/api";
import AddAddress from "./AddAddress";
import EditAddress from "./EditAddress";
import "./ProfileUser.css";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

function ProfileUser() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [addressIdForEdit, setAddressIdForEdit] = useState(null);

  const { userData } = useSelector((st) => st.auth);

  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((st) => st.auth);
  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);
  const [editMode, setEditMode] = useState(false);
  const [editAdressMode, setEditAdressMode] = useState(false);
  const {
    data: userQuery,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useGetLogedUserQuery();

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [
    updateUser,
    { isLoading: isUpdateLoading, isUpdateError, updateerror },
  ] = useUpdateUserDataMutation();
  useEffect(() => {
    setProfileData({
      name: userData?.name ?? "",
      email: userData?.email ?? "",
      phone: userData?.phone ?? "",
    });
  }, [userData]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      const res = await updateUser(profileData);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Address added successfully");
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container rounded bg-white mb-5 mt-md-5">
      {isUserLoading && <Loading />}
      {isUserError && <ServerError />}
      <EditAddress
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        addressIdForEdit={addressIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />
      <AddAddress
        setOpenAddModal={setOpenAddModal}
        openAddModal={openAddModal}
      />
      <div className="row justify-content-center">
        <div className="col-md-3 border-right">
          <div className="d-flex flex-column align-items-center text-center p-3 pb-5 pt-0">
            <img
              alt=""
              className="rounded-circle mt-5"
              width="150px"
              src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
            />
            <span className="font-weight-bold">{userData?.name}</span>
            <span className="text-black-50">{userData?.email}</span>
            <span className="mt-2">
              {" "}
              <Button
                onClick={() => {
                  navigate(-1);
                }}
              >
                {language == "ar"
                  ? "العودة الي الصفحة الاخيرة"
                  : "back to the last page"}
              </Button>
            </span>
          </div>
        </div>
        <div className="col-md-6 border-right">
          <div className="p-3 pb-5 pt-0">
            <div className="d-flex justify-content-center align-items-center mb-3">
              <h4 className="text-right">
                {" "}
                {language == "ar" ? "الملف الشخصي" : "Profile"}
              </h4>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label className="labels">
                  {" "}
                  {language == "ar" ? "الاسم بالكامل" : "Full Name"}
                </label>
                <input
                  type="text"
                  name="name"
                  value={profileData.name}
                  onChange={(e) =>
                    setProfileData((prev) => {
                      return { ...prev, [e.target.name]: e.target.value };
                    })
                  }
                  disabled={!editMode}
                  className="form-control"
                  placeholder="enter Full name"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label className="labels">
                  {language == "ar" ? "البريد الالكتروني" : "Email"}
                </label>
                <input
                  name="email"
                  type="text"
                  value={profileData.email}
                  onChange={(e) =>
                    setProfileData((prev) => {
                      return { ...prev, [e.target.name]: e.target.value };
                    })
                  }
                  disabled={!editMode}
                  className="form-control"
                  placeholder="enter Email"
                />
              </div>
              <div className="col-md-12 mt-2">
                <label className="labels">
                  {language == "ar" ? "رقم الهاتف" : "Mobile Number"}
                </label>
                <input
                  type="text"
                  name="phone"
                  disabled={!editMode}
                  value={profileData.phone}
                  onChange={(e) =>
                    setProfileData((prev) => {
                      return { ...prev, [e.target.name]: e.target.value };
                    })
                  }
                  className="form-control"
                  placeholder="enter phone number"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className={`col-md-${editMode ? 9 : 12}`}>
                <label className="labels">
                  {language == "ar" ? "العنوان" : "address"}
                </label>
                <input
                  type="text"
                  value={
                    userData?.addresses?.length > 0
                      ? userData?.addresses[0].full_address
                      : ""
                  }
                  disabled={!editAdressMode}
                  className="form-control"
                  placeholder="Add Adress"
                />
              </div>
              {editMode && (
                <div className="col-md-3 d-flex align-items-end">
                  {userData?.addresses?.length > 0 ? (
                    <button
                      onClick={() => {
                        setOpenEditModal(true);
                        setAddressIdForEdit(() => {
                          return (
                            userData?.addresses?.length > 0 &&
                            userData.addresses[0].id
                          );
                        });
                        setRerenderEditKey((prev) => prev + 1);
                      }}
                      className="btn btn-primary profile-button w-100"
                      type="button"
                    >
                      {language == "ar" ? "تعديل" : "edit"}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setOpenAddModal(true);
                      }}
                      className="btn btn-primary profile-button w-100"
                      type="button"
                    >
                      {language == "ar" ? "اضافة" : "add"}
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="mt-5 text-center">
              {editMode ? (
                <Button
                  loading={isUpdateLoading}
                  onClick={handleSaveClick}
                  type="primary"
                  size="large"
                >
                  {language == "ar" ? "حفظ" : "save"}
                </Button>
              ) : (
                <Button onClick={handleEditClick} type="primary" size="large">
                  {language == "ar" ? "تعديل" : "edit"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileUser;
