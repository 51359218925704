import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Data } from "../../constants";
import {
  faPlus,
  faStar,
  faStarHalf,
  faStarHalfStroke,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./ProductsControl.css";
import { Button, Flex, message, Modal, Space, Upload } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import AOS from "aos";
import TextArea from "antd/es/input/TextArea";
import AddProduct from "./AddProduct";
import EditOffer from "./EditProduct";
import Loading from "../../components/utils/Loading";
import { Spin } from "antd";

import {
  useDeleteProductMutation,
  useGetAllCategoriesQuery,
  useGetAllProductsQuery,
  useMakeItemSpecialOrNotMutation,
} from "../../redux/Service/api";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import EditProduct from "./EditProduct";
import { StarOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";
import ServerError from "../../components/utils/ServerError";

AOS.init({
  once: false,
});
const { confirm } = Modal;
const ProductsControl = () => {
  const catsref = useRef();
  const navigate = useNavigate();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [productIdForEdit, setproductIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const [filterdProducts, setFilterdProducts] = useState(null);

  const {
    data: allProductsData,
    isError: isProdsError,
    isLoading: isProdsLoading,
    error: prodsError,
  } = useGetAllProductsQuery();
  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
    error: catError,
  } = useGetAllCategoriesQuery();

  const allProducts = allProductsData?.data ?? null;

  const allCategories = allCategoriesData?.data ?? null;

  const handleCatNameClick = (ele, catID) => {
    let lis = [...catsref.current.children];

    lis.forEach((li) => {
      li.classList?.remove("active");
    });

    ele.target.classList?.add("active");
    setFilterdProducts(() => {
      if (catID == 0) {
        return allProducts;
      } else {
        return allProducts?.filter((product) => product.category.id == catID);
      }
    });
  };
  useEffect(() => {
    setFilterdProducts(allProducts);
  }, [allProducts]);

  const [
    switchSpecial,
    { isLoading: switchIsLoading, isError: switchIsError, error: switchError },
  ] = useMakeItemSpecialOrNotMutation();
  const [
    deleteProduct,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteProductMutation();

  const handleDeleteProduct = async (id) => {
    confirm({
      title: "Do you really want to delete this Product?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Product will be removed ",
      async onOk() {
        try {
          const res = await deleteProduct(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Product is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <section className="products section_padding mt-0 pt-0 position-relative">
      <h2 className="main_heading">Our Products</h2>
      {contextHolder}
      {isProdsLoading && <Loading />}
      {isProdsError && <ServerError />}

      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add a product{" "}
        </Button>
      </Flex>
      <ul className="products_categories flex_center d-flex" ref={catsref}>
        <li
          className="active"
          data-filter="all"
          onClick={(e) => handleCatNameClick(e, 0)}
        >
          All
        </li>

        {allCategories?.map((category) => (
          <li
            data-filter={`.cat${category.id}`}
            key={category.id}
            onClick={(e) => handleCatNameClick(e, category.id)}
          >
            {category.name}
          </li>
        ))}
      </ul>
      <Container className="my-5">
        <Row>
          {filterdProducts?.map((prod) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={prod.id}
              className={`mb-4 ${prod.category.id} mix`}
            >
              <div
                className="product"
                onClick={() => navigate(`/Products/${prod.id}`)}
                data-aos="fade-up"
              >
                <div className="image_hold">
                  <img src={prod.icon} alt="product" className="mw-100" />
                  <ul
                    className="product_optionn d-flex"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <li
                      onClick={() => {
                        setproductIdForEdit(prod.id);
                        setOpenEditModal(true);
                        setRerenderEditKey((prev) => prev + 1);
                      }}
                    ></li>
                    <li onClick={() => handleDeleteProduct(prod.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </li>
                    {prod.special ? (
                      <li
                        title="make not special"
                        onClick={() => {
                          switchSpecial(prod.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faStar} />
                        {switchIsLoading && <Spin style={{ color: "red" }} />}
                      </li>
                    ) : (
                      <li
                        title="make special"
                        onClick={() => {
                          switchSpecial(prod.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faStarHalfStroke} />
                        {switchIsLoading && <Spin style={{ color: "red" }} />}
                      </li>
                    )}
                  </ul>
                </div>
                <div className="product_info">
                  <p className="image_desc mb-2">
                    <span>{prod.name}</span>
                  </p>

                  <div className="price">
                    <span
                      className={
                        prod.discount !== 0 ? "oldprice" : "currentPrice"
                      }
                    >
                      EGP {prod.price}
                    </span>
                    {prod.discount !== 0 && (
                      <span className="currentPrice">EGP {prod.discount}</span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <AddProduct
          setOpenAddModal={setOpenAddModal}
          openAddModal={openAddModal}
        />

        <EditProduct
          rerenderEditKey={rerenderEditKey}
          setOpenEditModal={setOpenEditModal}
          openEditModal={openEditModal}
          productIdForEdit={productIdForEdit}
        />
      </Container>
    </section>
  );
};

export default ProductsControl;
