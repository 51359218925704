import { Col, Container, Row } from "react-bootstrap";
import Loading from "../../components/utils/Loading";
import { useGetOneArticleQuery } from "../../redux/Service/api";
import { useParams } from "react-router-dom";
import { Image } from "antd";
import "./Article.css";
import ServerError from "../../components/utils/ServerError";
const Article = () => {
  const { id } = useParams();
  const {
    data: allArticleDetails,
    isError: isAllArticlesError,
    isLoading: isAllArticlesLoading,
    error: allArticlesError,
  } = useGetOneArticleQuery(id);

  const articleDetails = allArticleDetails?.data ?? null;

  return (
    <section
      className="position-relative pt-5 container"
      style={{ minHeight: "50vh" }}
    >
      {isAllArticlesLoading && <Loading />}
      {isAllArticlesError && <ServerError />}
      {/* <main>
        <Container>
          <Row className="">
            <Col className="col-4">
              <Image src={articleDetails?.photo} style={{ width: "25rem" }} />
            </Col>
            <Col>
              <div className="d-flex flex-column">
                <h3>{articleDetails?.title}</h3>
                <h6>{articleDetails?.author}</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <section className="">
              <div className="">{articleDetails?.content}</div>
            </section>
          </Row>
        </Container>
      </main> */}
      {/*  */}

      <div className="two-thirds-image-left-one-third-text-right">
        <div className="image">
          <img src={articleDetails?.photo} />
        </div>
        <div className="text">
          <h1 className="title">{articleDetails?.title}</h1>
          <span className="lead">{articleDetails?.author}</span>
          <p>{articleDetails?.content}</p>
        </div>
      </div>
    </section>
  );
};

export default Article;
