import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import {
  Button,
  DatePicker,
  Image,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Upload,
} from "antd";

import {
  useEditCategoryMutation,
  useEditTipMutation,
  useGetFullCategoryDetailsQuery,
  useGetFullTipDetailsQuery,
} from "../../redux/Service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";

const EditTip = ({
  setOpenEditModal,
  openEditModal,
  tipIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);

  const [authorName, setAuthorName] = useState("");
  const [authorNameEN, setAuthorNameEN] = useState("");
  const [content, setContent] = useState("");
  const [contentEN, setContentEN] = useState("");
  const [authorJob, setAuthorJob] = useState("");
  const [authorJobEN, setAuthorJobEN] = useState("");

  const {
    data: allTipData,
    isError: isAllTipError,
    isLoading: isAllTipLoading,
    error: allTipError,
  } = useGetFullTipDetailsQuery(tipIdForEdit);

  const allTipDetails = allTipData?.data ?? null;

  useEffect(() => {
    setAuthorName(allTipDetails?.author);
    setAuthorNameEN(allTipDetails?.author_en);
    setAuthorJob(allTipDetails?.author_job);
    setAuthorJobEN(allTipDetails?.author_job_en);
    setContent(allTipDetails?.content);
    setContentEN(allTipDetails?.content_en);
  }, [allTipDetails, rerenderEditKey]);

  const [editTip, { isLoading: isEditLoading, isError: isEditError, error }] =
    useEditTipMutation();

  const handleEditTip = async () => {
    if (!authorName) {
      message.error("Please add the arabic author name");
      return;
    }
    if (!authorNameEN) {
      message.error("Please add the english author name");
      return;
    }
    if (!content) {
      message.error("Please add the arabic content");
      return;
    }
    if (!contentEN) {
      message.error("Please add the english author name");
      return;
    }
    if (!authorJob) {
      message.error("Please add the arabic author job");
      return;
    }
    if (!authorJobEN) {
      message.error("Please add the english author job");
      return;
    }

    const tipBody = {
      author: authorName,
      author_en: authorNameEN,
      content: content,
      content_en: contentEN,
      author_job: authorJob,
      author_job_en: authorJobEN,
    };

    try {
      const res = await editTip({ id: tipIdForEdit, tipBody });

      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("Tip added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding Tip:", error);
      message.error("Error adding Tip. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Tip"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer
    >
      {isAllTipLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllTipError && <p className="text-danger">Something went error</p>}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="اسم المؤلف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />

              <Input
                placeholder="وظيفة المؤلف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={authorJob}
                onChange={(e) => setAuthorJob(e.target.value)}
              />
              <TextArea
                placeholder="الوصف"
                style={{ direction: "rtl", textAlign: "right" }}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </Col>
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="author name in english"
                value={authorNameEN}
                onChange={(e) => setAuthorNameEN(e.target.value)}
              />

              <Input
                placeholder="Job in english"
                value={authorJobEN}
                onChange={(e) => setAuthorJobEN(e.target.value)}
              />
              <TextArea
                placeholder="Content"
                value={contentEN}
                onChange={(e) => setContentEN(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditTip}
              loading={isEditLoading}
            >
              edit
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditTip;
