import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Modal,
  Space,
  Upload,
  message,
  Button,
  Input,
  DatePicker,
  Select,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  useAddProdPhotoMutation,
  useDeleteProdPhotoMutation,
  useEditProductMutation,
  useGetAllCategoriesQuery,
  useGetFullProductDetailsQuery,
} from "../../redux/Service/api";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

const EditProduct = ({
  setOpenEditModal,
  openEditModal,
  productIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [modalKey1, setModalKey1] = useState(0);
  // input number Edit
  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [price, setPrice] = useState("");
  const [price$, setPrice$] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [priceAfterDiscount$, setPriceAfterDiscount$] = useState("");
  const [manual, setManual] = useState("");
  const [manualEN, setManualEN] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [available, setAvailable] = useState("active"); // nonActive
  const [productionDate, setProductionDate] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [iconImage, setIconImage] = useState(null);
  const [subImages, setSubImages] = useState(null);

  const {
    data: allCategoriessData,
    isError: isAllCategoriesError,
    isLoading: isAllCategoriesLoading,
    error: allCategoriesError,
  } = useGetAllCategoriesQuery();

  const allCategories = allCategoriessData?.data ?? null;

  const {
    data: allProductData,
    isError: isAllProductError,
    isLoading: isAllProductLoading,
    error: allProductError,
    refetch: refetchCategoryDetails,
  } = useGetFullProductDetailsQuery(productIdForEdit);

  const allProductDetails = allProductData?.data ?? null;

  useEffect(() => {
    setNameAR(allProductDetails?.name);
    setNameEN(allProductDetails?.name_en);
    setDescription(allProductDetails?.details);
    setDescriptionEN(allProductDetails?.details_en);
    setManual(allProductDetails?.manual);
    setManualEN(allProductDetails?.manual_en);
    setPrice(allProductDetails?.price);
    setPrice$(allProductDetails?.price_dollar);
    setPriceAfterDiscount(allProductDetails?.discount);
    setPriceAfterDiscount$(allProductDetails?.discount_dollar);
    setProductionDate(allProductDetails?.production_date);
    setCategoryId(allProductDetails?.category?.id);
    setIconImage([
      {
        uid: "-1",
        name: "Main-image",
        status: "done",
        url: allProductDetails?.icon,
      },
    ]);

    setSubImages(
      allProductDetails?.photos?.map((photo) => ({
        uid: photo.id,
        name: `image-${photo.id}`,
        status: "done",
        url: photo.path,
      }))
    );

    setModalKey1((prev) => prev + 11);
  }, [allProductDetails, rerenderEditKey]);
  //
  const [
    editProduct,
    { isLoading: isEditLoading, isError: isEditError, error },
  ] = useEditProductMutation();
  const [
    deleteSubPhoto,
    { isLoading: isDelLoading, isError: isDelError, error: delError },
  ] = useDeleteProdPhotoMutation();
  const [
    addSubPhoto,
    { isLoading: isAddLoading, isError: isAddError, adderror },
  ] = useAddProdPhotoMutation();

  const handleIconImageChange = (fileList) => {
    if (fileList.length > 0) {
      setIconImage(fileList[0].originFileObj);
    } else {
      setIconImage(null);
    }
  };

  const handleSubImagesChange = (fileList) => {
    if (fileList.length > 0) {
      const firstFourFiles = fileList.slice(0, 4);
      const fileObjects = firstFourFiles.map((file) =>
        file.originFileObj ? file.originFileObj : file
      );
      setSubImages(fileObjects);
    } else {
      setSubImages(null);
    }
  };

  const handleSubImageRemoval = (removedImage) => {
    deleteSubPhoto(removedImage.uid);
    const updatedFileList = subImages.filter(
      (file) => file.uid !== removedImage.uid
    );
    handleSubImagesChange(updatedFileList);
  };

  const handleEditProduct = async () => {
    if (!iconImage) {
      message.error("Please upload a main image");
      return;
    }
    if (!price) {
      message.error("Please insert the price");
      return;
    }
    if (!price$) {
      message.error("Please insert the price in dollar");
      return;
    }
    if (!nameEN) {
      message.error("Please add the english name");
      return;
    }
    if (!nameAR) {
      message.error("Please add the arabic name");
      return;
    }
    if (!available) {
      message.error("Please select the availability of product");
      return;
    }
    if (!description) {
      message.error("Please add the description");
      return;
    }
    if (!descriptionEN) {
      message.error("Please add the english description");
      return;
    }
    if (!manual) {
      message.error("Please add the how to use");
      return;
    }
    if (!manualEN) {
      message.error("Please add the how to use in english");
      return;
    }
    if (!productionDate) {
      message.error("Please select the production Date");
      return;
    }
    if (!categoryId) {
      message.error("Please select the categoryId");
      return;
    }

    const formData = new FormData();
    formData.append("name", nameAR);
    formData.append("name_en", nameEN);
    formData.append("available", available);
    formData.append("details", description);
    formData.append("details_en", descriptionEN);
    formData.append("manual", manual);
    formData.append("manual_en", manualEN);
    formData.append("production_date", productionDate);
    formData.append("category_id", categoryId);
    formData.append("price", price);
    formData.append("price_dollar", price$);

    if (typeof iconImage === "object" && !Array.isArray(iconImage)) {
      formData.append("icon", iconImage);
    }
    if (priceAfterDiscount && priceAfterDiscount > 0) {
      formData.append("discount", priceAfterDiscount);
    }
    if (priceAfterDiscount$ && priceAfterDiscount$ > 0) {
      formData.append("discount_dollar", priceAfterDiscount$);
    }
    const newPhotosFormData = new FormData();
    subImages?.forEach((file, index) => {
      if (file.size) {
        newPhotosFormData.append(`photos[${index}]`, file);
      }
    });
    try {
      const res = await editProduct({ id: productIdForEdit, formData });
      const res2 = await addSubPhoto({
        id: productIdForEdit,
        newPhotosFormData,
      });

      setIconImage(null);
      setSubImages(null);
      setNameAR("");
      setNameEN("");
      setPrice("");
      setPrice$("");
      setPriceAfterDiscount("");
      setPriceAfterDiscount$("");
      setAvailable("active");
      setCategoryId("");
      setDescription("");
      setDescriptionEN("");
      setManual("");
      setManualEN("");
      setProductionDate("");

      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Product added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding Product:", error);
      message.error("Error adding Product. Please try again.");
    }
  };

  const dateFormat = "YYYY-MM-DD";
  const defaultDate = productionDate ? dayjs(productionDate, dateFormat) : null;
  return (
    <Modal
      key={modalKey}
      title="Edit Product"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={800}
      footer
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Tooltip
                trigger={["hover"]}
                title={"اسم المنتج"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  placeholder="اسم المنتج"
                  style={{ direction: "rtl", textAlign: "right" }}
                  value={nameAR}
                  onChange={(e) => setNameAR(e.target.value)}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"كيفية الاستخدام"}
                placement="topLeft"
                overlayClassName="input"
              >
                <TextArea
                  placeholder="كيفية الاستخدام"
                  style={{ direction: "rtl", textAlign: "right" }}
                  value={manual}
                  onChange={(e) => setManual(e.target.value)}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"تفاصيل المنتج"}
                placement="topLeft"
                overlayClassName="input"
              >
                <TextArea
                  placeholder="تفاصيل المنتج"
                  style={{ direction: "rtl", textAlign: "right" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"السعر"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  type="number"
                  placeholder="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"السعر بالدولار"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  type="number"
                  placeholder="price in dollar"
                  value={price$}
                  onChange={(e) => setPrice$(e.target.value)}
                />
              </Tooltip>
            </Col>
            {/*  */}
            <Col className="gap-3 d-flex flex-column">
              <Tooltip
                trigger={["hover"]}
                title={"name in english"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  placeholder="name in english"
                  value={nameEN}
                  onChange={(e) => setNameEN(e.target.value)}
                  style={{ direction: "rtl", textAlign: "left" }}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"How to use in english"}
                placement="topLeft"
                overlayClassName="input"
              >
                <TextArea
                  placeholder="How to use in english"
                  value={manualEN}
                  onChange={(e) => setManualEN(e.target.value)}
                  style={{ direction: "rtl", textAlign: "left" }}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"Product details in english"}
                placement="topLeft"
                overlayClassName="input"
              >
                <TextArea
                  placeholder="Product details in english"
                  value={descriptionEN}
                  onChange={(e) => setDescriptionEN(e.target.value)}
                  style={{ direction: "rtl", textAlign: "left" }}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"Price after discount"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  type="number"
                  placeholder="Price after discount"
                  value={priceAfterDiscount}
                  onChange={(e) => setPriceAfterDiscount(e.target.value)}
                  style={{ direction: "rtl", textAlign: "left" }}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"Price after discount in dollar"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  type="number"
                  placeholder="Price after discount in dollar"
                  value={priceAfterDiscount$}
                  onChange={(e) => setPriceAfterDiscount$(e.target.value)}
                  style={{ direction: "rtl", textAlign: "left" }}
                />
              </Tooltip>
            </Col>
          </Row>
          {/*  */}
          <Row className="g-2">
            <Col className="">
              <Select
                key={modalKey1}
                placeholder="Select Category"
                style={{
                  width: 200,
                }}
                onChange={(e) => setCategoryId(e)}
                defaultValue={categoryId}
                options={allCategories?.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
              />
            </Col>
            <Col className="">
              <Select
                key={modalKey1}
                placeholder="Availability"
                style={{
                  width: 200,
                }}
                onChange={(e) => setAvailable(e)}
                defaultValue={available}
                options={[
                  {
                    value: "active",
                    label: "available",
                  },
                  {
                    value: "nonActive",
                    label: "not available",
                  },
                ]}
              />
            </Col>
            <Col className="">
              <DatePicker
                placeholder="Production Date"
                key={modalKey1}
                defaultValue={defaultDate}
                onChange={(dateObj, dateString) => {
                  setProductionDate(dateString);
                }}
              />
            </Col>
          </Row>
          {/*  */}
          <Row className="g-2 d-flex justify-content-between">
            <Col className="gap-1 d-flex flex-column col-5">
              <Upload
                key={modalKey1}
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleIconImageChange(fileList);
                }}
                listType="picture"
                maxCount={1}
                defaultFileList={iconImage}
              >
                <Button
                  loading={isAllProductLoading}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Main Images (Max: 1)
                </Button>
              </Upload>
            </Col>
            <Col className="gap-1 d-flex flex-column col-6">
              <Upload
                key={modalKey1}
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onRemove={(file) => {
                  handleSubImageRemoval(file);
                }}
                onChange={({ fileList }) => {
                  handleSubImagesChange(fileList);
                }}
                defaultFileList={subImages}
                listType="picture"
                maxCount={4}
                multiple
                loading={isDelLoading}
              >
                <Button
                  // loading={false}
                  loading={isDelLoading}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Sub Images (Max: 4)
                </Button>
              </Upload>
            </Col>
          </Row>

          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditProduct}
              loading={isEditLoading || isAllProductLoading}
            >
              Confirm
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditProduct;
