import { Col, Container, Row } from "react-bootstrap";
import "./Category.css";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import { useGetAllCategoriesQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";
AOS.init();
const Category = () => {
  const route = useNavigate();

  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
    error: catError,
  } = useGetAllCategoriesQuery();
  const allCategories = allCategoriesData?.data ?? null;

  if (isCatError) {
    return <ServerError />;
  }

  return (
    <section
      className="Category section_padding"
      data-aos={"fade-up"}
      style={{ position: "relative" }}
    >
      {isCatLoading && <Loading />}

      <h2 className="main_heading">
        {language === "ar" ? "الأقسام" : "Categories"}
      </h2>
      <Container>
        <Row className="flex_center">
          {allCategories?.map((category) => (
            <Col
              xs={12}
              md={6}
              lg={4}
              className="mb-4 mb-lg-4"
              key={category.id}
              // onClick={() => route(`/Products`)}
            >
              <div className="image_holder">
                <img
                  src={category.photo}
                  alt="Category"
                  className="w-100"
                  style={{ height: "416px" }}
                />
                <div className="cat">
                  <p>{category.name}</p>
                  <Link to={`/Products#${category.id}`}>
                    {language === "ar" ? "تسوق الآن" : "Shop Now"}
                  </Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Category;
