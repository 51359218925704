import { Button, message, Modal, Radio } from "antd";
import { useLayoutEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/utils/Loading";
import {
  decreaseItemQuantity,
  deleteChartItem,
  increaseItemQuantity,
} from "../../redux/action";
import {
  useCreateOrderFromCartMutation,
  useDeleteCartItemMutation,
  useGetCartProductsQuery,
} from "../../redux/Service/api";
import "./Chart.css";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const { confirm } = Modal;

const Chart = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userData } = useSelector((st) => st.auth);

  const [paymentWay, setPaymentWay] = useState(1);
  const onPaymentChange = (e) => {
    console.log("radio checked", e.target.value);
    setPaymentWay(e.target.value);
  };

  const userAddress =
    userData?.addresses?.length > 0 ? userData.addresses[0] : "";

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  const {
    data: cartData,
    isError: isCartError,
    isLoading: isCartLoading,
    error: cartError,
  } = useGetCartProductsQuery();

  const [
    deleteProduct,
    { isLoading: delIsLoading, isError: delIsError, error },
  ] = useDeleteCartItemMutation();

  const [
    createCartOrder,
    { isLoading: orderIsLoading, isError: orderIsError, error: orderError },
  ] = useCreateOrderFromCartMutation();

  const handleBuyByCash = async () => {
    if (!userAddress) {
      message.info("please add your address into your profile");
      confirm({
        title: "please add your address into your profile",
        icon: <ExclamationCircleFilled />,
        content:
          "When clicked the OK button, you will be redirected into your profile",
        async onOk() {
          navigate("/profile");
        },
        onCancel() {},
      });
      return;
    }
    confirm({
      title: "Are you sure you want to buy this order by cash",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Order will be confirmed",
      async onOk() {
        try {
          const orderBody = {
            payment_type: "cash",
            address_id: userAddress?.id,
          };
          const res = await createCartOrder(orderBody);
          res.data.message
            ? message.info(res.data.message)
            : message.success("order is created successfully");
        } catch (error) {
          console.log(error);
          message.info(error ? error.message : "some error happened");
        }
      },
      onCancel() {},
    });
  };

  return (
    <section className="Chart section_padding" style={{ position: "relative" }}>
      {isCartLoading && <Loading />}
      <h2 className="main_heading"> {language == "ar" ? "السلة" : "Cart"}</h2>
      {isCartError && <ServerError />}
      <Container>
        <Row>
          <Col xs={12} md={8}>
            {cartData?.items?.length > 0 ? (
              cartData?.items.map((ele) => (
                <div className="chartItem mb-5" key={ele.id}>
                  <div className="chartItem_image text-center">
                    <img src={ele.icon} alt="chartItem img" />
                  </div>
                  <div className="chartItem_info flex_center flex-column">
                    <p className="name">{ele.item_name}</p>

                    <div className="price">
                      <span className="currentPrice">
                        {ele.unit_price} {userData.is_dollar == 1 ? "$" : "EGP"}
                      </span>
                      {ele.oldprice && (
                        <span className="oldprice">
                          {ele.oldprice} {userData.is_dollar == 1 ? "$" : "EGP"}
                        </span>
                      )}
                    </div>
                    <ul className="chartItem_control">{ele.count}</ul>
                    <p className="chartItem_total">
                      <span className="chartItem_total_name">
                        {language == "ar" ? "السعر الكلي " : "Total"}:
                      </span>
                      <span className="total">
                        {ele.price} {userData.is_dollar == 1 ? "$" : "EGP"}
                      </span>
                    </p>
                    <div className="confirm d-flex gap-2">
                      <Button
                        className="Remove d-flex align-items-center p-3 fw-bold fs-6"
                        onClick={() => deleteProduct(ele.id)}
                        loading={delIsLoading}
                      >
                        {language == "ar" ? "ازالة المنتج " : "Remove"}
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="noItems">
                {language == "ar" ? "لا يوحد منتاجات " : "No Items yet"}
              </p>
            )}
          </Col>
          <Col
            xs={{ span: 12, order: "first" }}
            md={{ span: 4, order: "last" }}
            className="mb-5 mb-md-0"
          >
            <div className="CartSummary">
              <p className="CartSummary_name">
                {language == "ar" ? "التفاصيل " : "Cart Summary"}
              </p>
              {cartData?.items &&
                cartData?.items.map((ele) => (
                  <p className="CartSummary_item" key={ele.item_id}>
                    <span className="CartSummary_item_name">
                      {language == "ar"
                        ? `${ele.item_name} x ${ele.count} :`
                        : `${ele.count} x ${ele.item_name} :`}
                    </span>
                    <span className="CartSummary_item_price">
                      {ele.price} {ele.is_dollar == 1 ? "$" : "EGP"}
                    </span>
                  </p>
                ))}
              <p className="CartSummary_total">
                <span className="CartSummary_total_name">
                  {language == "ar" ? "السعر الكلي " : "Total"}:
                </span>
                <span className="CartSummary_total_number">
                  {cartData?.items?.length > 0 ? cartData?.total_price : 0}{" "}
                  {userData.is_dollar == 1 ? "$" : "EGP"}
                </span>
              </p>
            </div>

            <div className="CartSummary my-3 d-flex justify-content-between align-items-center flex-column">
              <Radio.Group
                onChange={onPaymentChange}
                value={paymentWay}
                className=" mb-3"
              >
                <Radio value={1}>
                  {language == "ar" ? "الدفع عند الاستلام" : "Cash on Delivery"}
                </Radio>
                <Radio disabled value={2}>
                  {language == "ar" ? "اونلاين" : "Online"}
                </Radio>
              </Radio.Group>
              {paymentWay === 1 ? (
                <Button
                  type="primary"
                  ghost
                  size="large"
                  onClick={handleBuyByCash}
                  disabled={!cartData?.items?.length > 0}
                  className=""
                >
                  {language == "ar" ? "شراء" : "Buy"}
                </Button>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Chart;
