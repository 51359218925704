import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import {
  faInstagram,
  faFacebookF,
  faPinterestP,
  faSquareFacebook,
  faSquareInstagram,
  faYoutube,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { language } from "../../language/Language";

// lahfa
import { Link } from "react-router-dom";

import { images } from "../../constants";

const Footer = () => {
  const route = useNavigate();

  return (
    // <footer data-aos="fade-up">
    //   <Container>
    //     <Row>
    //       <Col xs={6} md={3} className="mb-5 mb-md-0">
    //         <p className="footer_title">Categories</p>
    //         <ul className="footer_list">
    //           <li>
    //             <a href="/products">Men</a>
    //           </li>
    //           <li>
    //             <a href="/products">Women</a>
    //           </li>
    //           <li>
    //             <a href="/products">Shoes</a>
    //           </li>
    //           <li>
    //             <a href="/products">Watches</a>
    //           </li>
    //         </ul>
    //       </Col>
    //       <Col xs={6} md={3} className="mb-5 mb-md-0">
    //         <p className="footer_title">Help</p>
    //         <ul className="footer_list">
    //           <li>
    //             <a href="/">Track Order</a>
    //           </li>
    //           <li>
    //             <a href="/">Returns</a>
    //           </li>
    //           <li>
    //             <a href="/">Shipping</a>
    //           </li>
    //           <li>
    //             <a href="/">FAQs</a>
    //           </li>
    //         </ul>
    //       </Col>
    //       <Col xs={6} md={3}>
    //         <p className="footer_title">Get In Touch</p>
    //         <address>
    //           Any questions? Let us know in store at 9th floor, X Faisal St,
    //           Giza, Egypt or call us on (+2) 0123456789
    //         </address>
    //         <ul className="social">
    //           <li>
    //             <a href="https://www.facebook.com/OrganicaGroupBeauty?mibextid=ZbWKwL" target="_blank">
    //               <FontAwesomeIcon icon={faFacebookF} />
    //             </a>
    //           </li>
    //           <li>
    //             <a href="https://www.facebook.com/OrganicaGroup?mibextid=ZbWKwL" target="_blank">
    //               <FontAwesomeIcon icon={faFacebookF} />
    //             </a>
    //           </li>
    //           <li>
    //             <a href="https://www.facebook.com/profile.php?id=100064186775964&mibextid=ZbWKwL" target="_blank">
    //               <FontAwesomeIcon icon={faFacebookF} />
    //             </a>
    //           </li>
    //           <li>
    //             <a href="https://www.instagram.com/organica.group?igsh=MTdrMHFyMnNvNXdudw==" target="_blank">
    //               <FontAwesomeIcon icon={faInstagram} />
    //             </a>
    //           </li>
    //         </ul>
    //       </Col>
    //       <Col xs={6} md={3}>
    //         <p className="footer_title">Newsletter</p>
    //         <form action="">
    //           <input type="text" placeholder="email@examble.com" />
    //           <div className="focus_input"></div>
    //         </form>
    //         <button className="custom_button" onClick={() => route("/")}>
    //           Subscribe
    //         </button>
    //       </Col>
    //     </Row>
    //     <div className="copyright">
    //       Copyright ©2022 All rights reserved | This template is made with &lt;3
    //       by Khaled
    //     </div>
    //   </Container>
    // </footer>
    // lahfa
    <footer className=" pt-5 mt-3 box_shadow mt-5">
      <div className="footer container">
        <div className="row px-4 px-lg-0 mb-2">
          {/* col-2 */}
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language == "ar" ? "شركه" : "a company"}</h6>
            </li>
            <li>
              <Link to="/About">
                {language == "ar" ? "من نحن" : "About Us"}
              </Link>
            </li>
            <li>
              <Link to="/Contact">
                {language == "ar" ? "تواصل معانا" : "Connect Us"}
              </Link>
            </li>
          </ul>
          {/* col-2 */}

          <ul className="col-12 col-sm-12 col-lg-5 col-xl-2 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language == "ar" ? "روابط مهمه" : "Important links"}</h6>
            </li>
            <li>
              <Link to="/Products">
                {language === "ar" ? "منتجاتنا" : "Products"}
              </Link>
            </li>
            {/* <li>
              <Link to="/Articles">
                {language === "ar" ? "مقالات" : "Articles"}
              </Link>
            </li> */}
          </ul>
          <ul className="col-12 col-sm-12 col-lg-5 col-xl-3 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>
                {language == "ar" ? "تريد مساعده؟" : "do you want help ?"}
              </h6>
            </li>
            <li>
              <Link to="mailto:info@organica-group.com">
                <i className="bi bi-envelope"></i> info@organica-group.com
              </Link>
            </li>
            <li>
              <Link to="#">
                <i className="bi bi-phone"></i> +201000525228
              </Link>
            </li>
          </ul>
          {/* col-4 */}
          {/* <ul className="col-12 col-sm-12 col-lg-5 col-xl-4 text-start-fremwork mb-2 flex-column">
            <li>
              <h6>{language == "ar" ? "موقعنا" : "Our Location"}</h6>
            </li>
            <li>
              <Link
              to="http://bit.ly/LahfaShubraStore"
              target="_blank"
              className="gap-2 d-flex flex-nowrap"
            >
              <i className="bi bi-geo-alt"></i>
              {language == "ar"
                ? "شبرا الخيمة :- شارع ١٥ مايو ، امام مايو مول ، بالقرب من قهوة شرف ، شبرا الخيمة"
                : "Shubra El-Kheima: May 15th Street, in front of Mayo Mall, near Sharaf Coffee, Shubra El-Kheima."}
            </Link>
              <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d431.39324198232083!2d31.2650701!3d30.1186373!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458150efa089caf%3A0xed2c6fdf1b340c24!2z2YTZh9mB2Kkg2YTZhNij2KvYp9irINin2YTYsdin2YLZiQ!5e0!3m2!1sar!2seg!4v1704212303226!5m2!1sar!2seg" width="300" height="200" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </li>
          </ul> */}
        </div>
        <div className="row px-4 px-lg-0 justify-content-around d-flex">
          <ul className="col-12 col-lg-4 text-start-fremwork flex-column">
            <li>
              <h6>
                {language == "ar" ? "نحن نقبل" : "We accept"}
                {/* {t("We_accept")} */}
              </h6>
            </li>
            <li className="imges d-flex flex-wrap gap-2">
              <img src={images.cash1} alt="" />
              <img src={images.cash2} alt="" />
              <img src={images.cash3} alt="" />
              <img src={images.cash4} alt="" />
              <img src={images.cash5} alt="" />
              <img src={images.cash6} alt="" />
            </li>
          </ul>

          <div className="col-12 col-lg-4 text-start-fremwork ">
            <h6 className="heading_meada">
              {language == "ar" ? "تابعنا" : "Follow us"}
              {/* {t("Follow_us")} */}
            </h6>
            <div className="meada">
              <a
                href="https://www.facebook.com/OrganicaGroupBeauty?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className=" bi-facebook"></i> */}
                <FontAwesomeIcon icon={faSquareFacebook} />
              </a>
              <a
                href="https://www.facebook.com/OrganicaGroup?mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className=" bi-facebook"></i> */}
                <FontAwesomeIcon icon={faSquareFacebook} />
              </a>
              <a
                href="https://www.instagram.com/organica.group?igsh=MTdrMHFyMnNvNXdudw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className=" bi-instagram"></i> */}
                <FontAwesomeIcon icon={faSquareInstagram} />
              </a>
              {/* <a
                href="https://www.linkedin.com/company/lahfa-furniture"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faSquareXTwitter} />
              </a>
              <a
                href="https://www.youtube.com/@user-pe8tb1xz6z"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <p className="m-0 p-3 copay">
        {language == "ar"
          ? "Ⓒ جميع الحقوق محفوظة لشركة"
          : "Ⓒ All rights reserved to"}{" "}
        <a
          href="https://prosofteg.com"
          target="_blank"
          rel="noopener noreferrer"
          className="ms-1 me-1 mt-0 mb-0"
        >
          {" "}
          ProsoftEg
        </a>
      </p>
    </footer>
  );
};

export default Footer;
