import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, Space, Upload, message, Button, DatePicker, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import InputNumber from "../../components/InputNumber/InputNumber";
import { useAddOfferMutation } from "../../redux/Service/api";

const { RangePicker } = DatePicker;
const AddOffer = ({ setOpenAddModal, openAddModal }) => {
  const [modalKey, setModalKey] = useState(0);
  // input number Edit
  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [price, setPrice] = useState("");
  const [price$, setPrice$] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const [file, setFile] = useState(null);

  const [addOffer, { isLoading, isError, error }] = useAddOfferMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddOffer = async () => {
    if (!file) {
      message.error("Please upload a file");
      return;
    }
    if (!price) {
      message.error("Please insert the price");
      return;
    }
    // if (!nameEN) {
    //   message.error("Please add the english name");
    //   return;
    // }
    // if (!nameAR) {
    //   message.error("Please add the arabic name");
    //   return;
    // }
    const formData = new FormData();
    formData.append("available", "active");
    formData.append("icon", file);
    // formData.append("title", nameAR);
    // formData.append("title_en", nameEN);
    formData.append("price", price);
    formData.append("price_dollar", price$);
    formData.append("from", dateFrom);
    formData.append("to", dateTo);

    try {
      const res = await addOffer(formData);
      setFile(null);
      // setNameAR("");
      // setNameEN("");
      setPrice("");
      setPrice$("");
      setDateFrom("");
      setDateTo("");
      setModalKey((prev) => prev + 1);
      res.data.message
        ? message.info(res.data.message)
        : message.success("Offer added successfully");
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding offer:", error);
      message.error("Error adding offer. Please try again.");
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    setDateFrom(dateStrings[0]);
    setDateTo(dateStrings[1]);
  };
  return (
    <Modal
      key={modalKey}
      title="Add Offers"
      centered
      open={openAddModal}
      onCancel={() => setOpenAddModal(false)}
      style={{ direction: "ltr", textAlign: "left" }}
      width={500}
      footer
    >
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              {/* <Input
                placeholder="name in arabic"
                value={nameAR}
                onChange={(e) => setNameAR(e.target.value)}
              />
              <Input
                placeholder="name in english"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              /> */}
              <Input
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                style={{ direction: "ltr", textAlign: "left" }}
              />
              <Input
                placeholder="Price in dollar"
                value={price$}
                onChange={(e) => setPrice$(e.target.value)}
                style={{ direction: "ltr", textAlign: "left" }}
              />
              <RangePicker onChange={handleDateChange} />
            </Col>
          </Row>
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column col-4">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  loading={false}
                  icon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Upload Image (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button type="primary" onClick={handleAddOffer} loading={isLoading}>
              add offer
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default AddOffer;
