import { Button } from "antd";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../redux/Service/api";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [loginUser, { isLoading, isError, error }] = useLoginMutation();

  const handleLoginClick = async (e) => {
    e.preventDefault();

    try {
      const result = await loginUser({
        email: loginData.email,
        password: loginData.password,
      });

      const token = result?.data?.data?.token ?? null;
      if (token) {
        localStorage.setItem("userToken", token);

        navigate("/");
      } else {
        setErrorMessage(
          result.error?.message ||
            result?.data?.message ||
            "something went wrong"
        );
        console.log("err");
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  return (
    <section className="login flex_center" style={{ direction: "ltr" }}>
      <div className="login_cont">
        <h2>Login</h2>
        <p>Enter Login details to get access</p>
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
        <form action="">
          <label htmlFor="email">Username Or Email Address</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder=" Email Address"
            value={loginData.email}
            onChange={(e) =>
              setLoginData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          <label htmlFor="password">Username Or Email Address</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter Password"
            value={loginData.password}
            onChange={(e) =>
              setLoginData((prev) => {
                return { ...prev, [e.target.name]: e.target.value };
              })
            }
          />
          <div className="log_info flex_between">
            <div className="log_keep">
              <input type="checkbox" name="keeplog" id="keeplog" />
              <label htmlFor="keeplog" className="keeplabel">
                Keep Me Logged In
              </label>
            </div>
          </div>
          <div className="logEnd flex_between">
            <p className="tosignup">
              Don't have an account?{" "}
              <Link className="signLink" to="/SignUp">
                Sign Up
              </Link>{" "}
              here
            </p>
            <Button
              type="submit"
              className="custom_button px-4 pt-1 pb-1"
              onClick={handleLoginClick}
              loading={isLoading}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Login;
