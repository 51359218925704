import {
  faDownload,
  faSquarePen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, message, Modal, Space, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";

import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import AddArticle from "./AddArticle";
import "./AddArticles.css";
import EditArticle from "./EditArticle";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  useDeleteArticleMutation,
  useGetAllArticlesQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import ServerError from "../../components/utils/ServerError";

const { confirm } = Modal;

function ArticlesControl() {
  // const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [articleIdForEdit, setArticleIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const {
    data: allArticlesData,
    isError: isAllArticlesError,
    isLoading: isAllArticlesLoading,
    error: allArticlesError,
  } = useGetAllArticlesQuery();

  const allArticles = allArticlesData?.data ?? null;

  const [
    deleteArticle,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteArticleMutation();

  const handleDeleteArticle = async (id) => {
    confirm({
      title: "Do you really want to delete this?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this will be removed ",
      async onOk() {
        try {
          const res = await deleteArticle(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("review is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <div
      className="gap-3 position-relative"
      dir="ltr"
      style={{ direction: "ltr" }}
    >
      {/* <h2 className="main_heading">Articles</h2> */}
      <h2 className="main_heading">Testimonials</h2>
      {isAllArticlesLoading && <Loading />}
      {isAllArticlesError && <ServerError />}
      <div className="d-flex justify-content-center align-items-center mb-5 mt-4">
        <Button type="primary" onClick={() => setOpenAddModal(true)}>
          add
        </Button>
      </div>
      <section className="light">
        <div className="cards d-flex flex-wrap gap-4 justify-content-center position-relative mb-5">
          {allArticles?.map((article) => (
            <>
              {/* <article className="postcard light blue" key={article.id}>
              <div className="postcard__img_link">
                <img
                  className="postcard__img"
                  src={article.photo}
                  alt=" Title"
                />
              </div>
              <div className="postcard__text t-dark">
                <ul className="postcard__tagbox gap-3 my-2">
                  <Button
                    danger
                    type="primary"
                    icon={<FontAwesomeIcon size="lg" icon={faTrash} />}
                    onClick={() => {
                      handleDeleteArticle(article.id);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    type="primary"
                    icon={<FontAwesomeIcon size="lg" icon={faSquarePen} />}
                    onClick={() => {
                      setOpenEditModal(true);
                      setArticleIdForEdit(article.id);
                      setRerenderEditKey((prev) => prev + 1);
                    }}
                  >
                    Update
                  </Button>
                </ul>
              </div>
            </article> */}
              <Card
                key={article.id}
                hoverable
                className="col-3"
                loading={delIsLoading}
                cover={
                  <img alt="slide-icon" src={article.photo} height={200} />
                }
                actions={[
                  <DeleteFilled
                    key="setting"
                    size="large"
                    onClick={() => {
                      handleDeleteArticle(article.id);
                    }}
                  />,
                  //   <Switch defaultChecked />
                ]}
              >
                <div className=" position-relative"></div>
              </Card>
            </>
          ))}
        </div>
      </section>

      <EditArticle
        setOpenEditModal={setOpenEditModal}
        openEditModal={openEditModal}
        articleIdForEdit={articleIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />

      <AddArticle
        setOpenAddModal={setOpenAddModal}
        openAddModal={openAddModal}
      />
    </div>
  );
}

export default ArticlesControl;
