import "./Store.css";
import { language } from "../../language/Language";
const Store = () => {
  return (
    <section className="store flex_center" data-aos={"fade-up"}>
      <div className="store_info flex_center">
        <p className="store_name">Organica</p>
        <address>
          {language === "ar"
            ? "شركة أورجانيكاجروب شركة رائدة في مستحضرات التجميل والمنتجات الطبية وخالية تماما من المواد الكيميائية حاصلة على شهاده االيزو ومصرحه من وزاره الصحه المصريه"
            : "Organica Group is a leading company in cosmetics and medical products and completely free of chemicals She holds an ISO certificate and is authorized by the Egyptian Ministry of Health"}
        </address>
      </div>
    </section>
  );
};

export default Store;
