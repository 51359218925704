import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import {
  Button,
  DatePicker,
  Image,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import InputNumber from "../../components/InputNumber/InputNumber";
import {
  useEditOfferMutation,
  useGetFullOfferDetailsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const { RangePicker } = DatePicker;

const EditOffer = ({
  setOpenEditModal,
  openEditModal,
  offerIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);
  const [modalKey1, setModalKey1] = useState(0);

  // input number Edit
  const [nameEN, setNameEN] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [price, setPrice] = useState("");
  const [price$, setPrice$] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const [file, setFile] = useState(null);

  const {
    data: allOfferData,
    isError: isAllOfferError,
    isLoading: isAllOfferLoading,
    error: allOfferError,
  } = useGetFullOfferDetailsQuery(offerIdForEdit);

  const allOfferDetails = allOfferData?.data ?? null;

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  console.log(allOfferDetails);

  useEffect(() => {
    // setNameAR(allOfferDetails?.title);
    // setNameEN(allOfferDetails?.title_en);
    setPrice(allOfferDetails?.price);
    setPrice$(allOfferDetails?.price_dollar);
    setDateFrom(allOfferDetails?.from);
    setDateTo(allOfferDetails?.to);
    setFile(allOfferDetails?.icon);

    setModalKey1((prev) => prev + 11);
  }, [allOfferDetails, rerenderEditKey]);

  const handleDateChange = (dates, dateStrings) => {
    setDateFrom(dateStrings[0]);
    setDateTo(dateStrings[1]);
  };

  const [editOffer, { isLoading: isEditLoading, isError: isEditError, error }] =
    useEditOfferMutation();

  const handleEditOffer = async () => {
    if (!price) {
      message.error("Please insert the price");
      return;
    }
    if (!price$) {
      message.error("Please insert the price in dollar");
      return;
    }
    // if (!nameEN) {
    //   message.error("Please add the english name");
    //   return;
    // }
    // if (!nameAR) {
    //   message.error("Please add the arabic name");
    //   return;
    // }
    if (!file) {
      message.error("Please upload a file");
      return;
    }
    const formData = new FormData();
    formData.append("available", "active");
    // formData.append("title", nameAR);
    // formData.append("title_en", nameEN);
    formData.append("price", price);
    formData.append("price_dollar", price$);
    formData.append("from", dateFrom);
    formData.append("to", dateTo);
    if (typeof file !== "string") {
      formData.append("icon", file);
    }
    try {
      const res = await editOffer({ id: offerIdForEdit, formData });
      setFile(null);
      // setNameAR("");
      setFile(null);
      // setNameEN("");
      setPrice("");
      setPrice$("");
      setDateFrom("");
      setDateTo("");
      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("Offer added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding offer:", error);
      message.error("Error adding offer. Please try again.");
    }
  };
  const dateFormat = "YYYY-MM-DD";
  const defaultStartDate = dateFrom ? dayjs(dateFrom, dateFormat) : null;
  const defaultEndDate = dateTo ? dayjs(dateTo, dateFormat) : null;

  return (
    <Modal
      key={modalKey}
      title="Edit Offer"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      style={{ direction: "ltr", textAlign: "left" }}
      width={500}
      footer
    >
      {isAllOfferLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllOfferError && <p className="text-danger">Something went error</p>}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              {/* <Input
                placeholder="name in arabic"
                value={nameAR}
                onChange={(e) => setNameAR(e.target.value)}
              />
              <Input
                placeholder="name in english"
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
              /> */}
              <Tooltip
                trigger={["hover"]}
                title={"price"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  placeholder="Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Tooltip>
              <Tooltip
                trigger={["hover"]}
                title={"price in dollar"}
                placement="topLeft"
                overlayClassName="input"
              >
                <Input
                  placeholder="Price in dollar"
                  value={price$}
                  onChange={(e) => setPrice$(e.target.value)}
                />
              </Tooltip>
              <RangePicker
                key={modalKey1}
                onChange={handleDateChange}
                defaultValue={[defaultStartDate, defaultEndDate]}
                format={dateFormat}
              />
              {/*  */}
              <Col className="gap-3 d-flex flex-column col-6">
                <Image src={file} loading={isAllOfferLoading} />
              </Col>
              <Row className="g-2">
                <Col className="gap-3 d-flex flex-column col-6">
                  <Upload
                    customRequest={({ file, onSuccess, onError }) => {
                      try {
                        onSuccess();
                      } catch (error) {
                        console.error("Error handling file:", error);
                      }
                    }}
                    onChange={({ fileList }) => {
                      handleFileChange(fileList);
                    }}
                    listType="picture"
                    maxCount={1}
                  >
                    <Button
                      loading={false}
                      icon={<FontAwesomeIcon icon={faDownload} />}
                    >
                      change Image (Max: 1)
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditOffer}
              loading={isEditLoading}
            >
              edit
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditOffer;
