import { images } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";

import "./Testimonials.css";
import { useGetAllTipsQuery } from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const Testimonials = () => {
  const {
    data: allTipsData,
    isError: isTipsError,
    isLoading: isTipsLoading,
    error: tipsError,
  } = useGetAllTipsQuery();
  const allTips = allTipsData?.data ?? null;

  if (isTipsError) {
    return <ServerError />;
  }

  if (allTips?.length === 0) {
    return;
  }

  return (
    <section
      className="Testimonials section_padding"
      data-aos={"fade-up"}
      style={{ position: "relative" }}
    >
      {isTipsLoading && <Loading />}

      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        //
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        //
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper Testimonials_cont"
      >
        {allTips ? (
          allTips?.map((tip) => (
            <SwiperSlide
              key={tip.id}
              className="test text-center flex_center flex-column position-relative r-test"
            >
              <p className="testi_text">
                <img
                  src={tip.photo}
                  alt=""
                  className="rounded"
                  style={{ height: "15rem", width: "100%" }}
                />
                {/* <span className="quotes">
                  <FaQuoteRight style={{ fontSize: "2rem" }} />
                </span> */}
              </p>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className="test text-center flex_center flex-column">
            <p className="test_text">
              {language === "ar"
                ? `يتأثر الناس بسهولة بالآخرين، خاصة عند التسوق
              متصل. ولهذا السبب فإن مواقع المراجعة المستقلة مثل المصري eShop،
              GoodReads، وغيرها الكثير تحظى بشعبية كبيرة.`
                : `People are easily swayed by others, especially when shopping
              online. This is why independent review sites like Elmasry-eShop,
              GoodReads, and many others are so popular.`}
            </p>
            <div className="test_info">
              <img src={images.testimonial1} alt="testimonial" />
              <div className="test_info_name">
                <p>{language === "ar" ? "بيتي كروزر" : "Petey Cruiser"}</p>
                <span>{language === "ar" ? "مصمم" : "Designer"}</span>
              </div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </section>
  );
};

export default Testimonials;
