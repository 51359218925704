import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLayoutEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/utils/Loading";
import { deleteFavItem } from "../../redux/action";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import "./Favourite.css";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const Favourite = () => {
  // const favItems = useSelector((state) => state.handlechart.fav);
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((st) => st.auth);
  useLayoutEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, []);

  const {
    data: favItems,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();

  return (
    <section className="Favourite section_padding position-relative">
      <h2 className="main_heading">
        {language == "ar" ? "المفضلة" : "Favourits"}
      </h2>
      {(isFavLoading || addOrDelIsLoading) && <Loading />}
      {isFavError && <ServerError />}
      <Container>
        <Row>
          {favItems?.data?.length > 0 ? (
            favItems?.data.map((ele) => (
              <Col xs={12} className="mb-5" key={ele.id}>
                <div className="favItem">
                  <div
                    className="favItem_remove"
                    onClick={() => {
                      ele.is_offer
                        ? addOrDeleteProduct({ offer_id: ele.item_id })
                        : addOrDeleteProduct({ item_id: ele.item_id });
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                  <div className="favItem_image text-center">
                    <img src={ele.icon} alt="favorite img" />
                  </div>
                  <div className="favItem_info flex_center flex-column">
                    <p className="name">
                      {ele.is_offer ? "offer" : ele.item_name}
                    </p>
                    <div className="price">
                      <span
                        className={ele?.discount ? "oldprice" : "currentPrice"}
                      >
                        EGP {ele?.price}
                      </span>
                      {ele?.discount && (
                        <span className="currentPrice">
                          EGP {ele?.discount}
                        </span>
                      )}
                    </div>

                    <div className="linkstochart d-flex gap-2">
                      <button
                        className="addtochart"
                        onClick={() => {
                          ele.is_offer
                            ? navigate(`/offer/${ele.item_id}`)
                            : navigate(`/Products/${ele.item_id}`);
                        }}
                      >
                        {language == "ar" ? "عرض المنتج" : "View"}
                      </button>
                      <Link className="gotochart custom_button" to="/Chart">
                        {language == "ar" ? "الذهاب الي السلة" : "Go To Cart"}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <p className="noItems">No Items yet</p>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Favourite;
