import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Upload,
  message,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  useDeleteSliderMutation,
  useGetSlidesQuery,
  useUploadSliderMutation,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { ExclamationCircleFilled } from "@ant-design/icons";
import ServerError from "../../components/utils/ServerError";

const { Meta } = Card;
const { confirm } = Modal;

function AddSlider() {
  const [OpenSliderModal, setOpenSliderModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [uploadSlider, { isLoading, isError, error }] =
    useUploadSliderMutation();
  const [
    deleteSlider,
    { isLoading: delIsLoading, isError: delIsError, error: delError },
  ] = useDeleteSliderMutation();

  const handleFileChange = (fileList) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddProduct = async () => {
    if (!file) {
      message.error("Please upload a file");
      return;
    }
    if (!title) {
      message.error("Please insert a title");
      return;
    }
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("title", title);
    formData.append("status", "active");

    try {
      const res = await uploadSlider(formData);
      setFile(null);

      setTitle("");
      setModalKey((prev) => prev + 1);
      message.success("Slider added successfully");
      setOpenSliderModal(false);
    } catch (error) {
      console.error("Error adding product:", error);
      message.error("Error adding slider. Please try again.");
    }
  };

  const {
    data: allSlidesData,
    isError: isSlidesError,
    isLoading: isSlidesLoading,
    error: slidesError,
  } = useGetSlidesQuery();
  const allSlides = allSlidesData?.data ?? null;

  const handleDeleteSlider = async (id) => {
    confirm({
      title: "Do you really want to delete this Slider?",
      icon: <ExclamationCircleFilled />,
      content: "When clicked the OK button, this Slider will be deleted",
      async onOk() {
        try {
          const res = await deleteSlider(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Slider is deleted successfully");
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {},
    });
  };

  return (
    <div className=" ">
      <h2 className="main_heading mb-4">Slider</h2>
      {(delIsError || isSlidesError) && <ServerError />}
      <Flex gap="small" wrap="nowrap" className="justify-content-center mb-5">
        <Button type="primary" onClick={() => setOpenSliderModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> Add a Slider{" "}
        </Button>
      </Flex>

      <div className="cards d-flex flex-wrap gap-4 justify-content-center position-relative mb-5">
        {isSlidesLoading && <Loading />}
        {allSlides?.map((slide) => (
          <Card
            key={slide.id}
            hoverable
            className="col-3"
            loading={delIsLoading}
            cover={<img alt="slide-icon" src={slide.photo} />}
            actions={[
              <DeleteFilled
                key="setting"
                size="large"
                onClick={() => {
                  handleDeleteSlider(slide.id);
                }}
              />,
              //   <Switch defaultChecked />
            ]}
          >
            <Meta title={slide.title} />
            <div className=" position-relative"></div>
          </Card>
        ))}
      </div>

      {/* add slider */}
      <Modal
        title="Add Slider"
        centered
        open={OpenSliderModal}
        onCancel={() => setOpenSliderModal(false)}
        width={1000}
        footer=""
        key={modalKey}
      >
        <div className="d-flex flex-wrap justify-content-between gap-5">
          <Row className="g-2 col-12 col-md-5">
            <Col md className="col-12 d-flex flex-column">
              <Input
                size="large"
                placeholder="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2 col-12">
            <Col md className="gap-3 d-flex flex-column">
              <Upload
                customRequest={({ file, onSuccess, onError }) => {
                  try {
                    onSuccess();
                  } catch (error) {
                    console.error("Error handling file:", error);
                  }
                }}
                onChange={({ fileList }) => {
                  handleFileChange(fileList);
                }}
                listType="picture"
                maxCount={1}
              >
                <Button
                  icon={<FontAwesomeIcon icon={faDownload} />}
                  loading={false}
                >
                  Upload (Max: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleAddProduct}
              loading={isLoading}
            >
              Add Product
            </Button>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default AddSlider;
