import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useEffect, useState } from "react";
import mixitup from "mixitup";
import {
  faCartArrowDown,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemtochart,
  addItemtofav,
  deleteFavItem,
} from "../../redux/action";
import AOS from "aos";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetAllCategoriesQuery,
  useGetAllProductsQuery,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { language } from "../../language/Language";
import { Spin } from "antd";
import { Button } from "antd";
import ServerError from "../../components/utils/ServerError";
// AOS.init({
//   once: false,
// });

const Products = () => {
  const [filterdProducts, setFilterdProducts] = useState(null);

  const dispatch = useDispatch();
  const catsref = useRef();
  const route = useNavigate();

  const {
    data: allProductsData,
    isError: isProdsError,
    isLoading: isProdsLoading,
    error: prodsError,
  } = useGetAllProductsQuery();
  const {
    data: allCategoriesData,
    isError: isCatError,
    isLoading: isCatLoading,
    error: catError,
  } = useGetAllCategoriesQuery();

  const allProducts = allProductsData?.data ?? null;
  const allCategories = allCategoriesData?.data ?? null;

  const catParam = window.location.hash.split("#").pop();

  useEffect(() => {
    if (!catParam) {
      setFilterdProducts(() => {
        if (allProducts) {
          const allFProducts = [...allProducts]?.sort(
            (a, b) => a.category.id - b.category.id
          );
          return allFProducts;
        }
      });
    } else {
      setFilterdProducts(
        allProducts?.filter((product) => product.category.id == catParam)
      );
    }
  }, [allProducts]);

  const [
    addOrDeleteProductInFavourite,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  const handlepropa = (e) => {
    e.stopPropagation();
  };
  const handlefav = (ele) => {
    addOrDeleteProductInFavourite({ item_id: ele.id });
  };

  const handleCatNameClick = (ele, catID) => {
    let lis = [...catsref.current.children];

    lis.forEach((li) => {
      li.classList?.remove("active");
    });

    ele.target.classList?.add("active");

    setFilterdProducts(() => {
      if (catID == 0) {
        const allFProducts = [...allProducts].sort(
          (a, b) => a.category.id - b.category.id
        );
        return allFProducts;
      } else {
        return allProducts?.filter((product) => product.category.id == catID);
      }
    });
  };

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   // mixitup(".row", {
  //   //   selectors: {
  //   //     target: ".mix",
  //   //   },
  //   //   animation: {
  //   //     duration: 600,
  //   //   },
  //   // });
  //   if (catParam) {
  //     // let lis = [...catsref.current.children];
  //     // lis.forEach((li) => {
  //     //   li.classList?.remove("active");
  //     // });

  //     setFilterdProducts(
  //       allProducts?.filter((product) => product.category.id == catParam)
  //     );
  //   }
  // }, []);

  if (isProdsError) {
    return <ServerError />;
  }

  return (
    <section
      className="products section_padding"
      style={{ position: "relative" }}
    >
      {isProdsLoading && <Loading />}

      <h2 className="main_heading">
        {language === "ar" ? "منتجاتنا" : "Our Products"}
      </h2>
      <ul className="products_categories flex_center" ref={catsref}>
        <li
          className={!catParam ? "active" : null}
          data-filter="all"
          onClick={(e) => handleCatNameClick(e, 0)}
        >
          {language === "ar" ? "الكل" : "All"}
        </li>

        {allCategories?.map((category) => (
          <li
            data-filter={`.cat${category.id}`}
            key={category.id}
            onClick={(e) => handleCatNameClick(e, category.id)}
            className={catParam == category.id ? "active" : null}
          >
            {category.name}
          </li>
        ))}
      </ul>
      <Container className="my-5">
        <Row>
          {filterdProducts?.map((prod) => (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={prod.id}
              className={`mb-4 cat${prod.category.id} mix`}
            >
              <div
                className="product"
                onClick={() => route(`/Products/${prod.id}`)}
                // data-aos="fade-up"
              >
                <div className="image_hold">
                  <img src={prod.icon} alt="product" className="mw-100" />
                </div>
                <div className="product_info">
                  <p className="image_desc">{prod.name}</p>
                  <div className="price">
                    <span
                      className={
                        prod.discount !== 0 ? "oldprice" : "currentPrice"
                      }
                    >
                      {prod.is_dollar == 1 ? "$" : "EGP"} {prod.price}
                    </span>
                    {prod.discount !== 0 && (
                      <>
                        <span className="currentPrice">
                          {prod.is_dollar == 1 ? "$" : "EGP"} {prod.discount}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="foote mt-3">
                    <ul className="product_option" onClick={handlepropa}>
                      <li
                        onClick={() => handlefav(prod)}
                        className={favItems
                          ?.map((el) =>
                            el.item_id === prod.id && !el.is_offer
                              ? "favicon"
                              : ""
                          )
                          .join("")}
                      >
                        {addOrDelIsLoading && <Spin />}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Products;
