import * as XLSX from "xlsx";

export const exportToExcel = (data, name) => {
  // Create a worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(data);
  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, `${name}`);
  // Generate a binary string representation of the workbook
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  // Create a Blob from the binary string
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  // Create a link element
  const link = document.createElement("a");
  // Set the href attribute to a URL representing the Blob
  link.href = URL.createObjectURL(blob);
  // Set the download attribute to specify the file name
  link.download = `${name}.xlsx`;
  // Append the link to the body
  document.body.appendChild(link);
  // Programmatically click the link to trigger the download
  link.click();
  // Remove the link from the document
  document.body.removeChild(link);
};
