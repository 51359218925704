import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../components/RootLayout";

import { ErrorPage } from "./ErrorPage";

import {
  About,
  WhiteFriday,
  Chart,
  Contact,
  Favourite,
  Home,
  Login,
  Product,
  Products,
  SignUp,
} from "../Pages";
import Dashboard from "../Dashboard";
import UsersControl from "../Dashboard/UsersControl/UsersControl";
import ProductsControl from "../Dashboard/ProductsControl/ProductsControl";
import OrdersControl from "../Dashboard/OrdersControl/OrdersControl";
import SpecialOffers from "../Dashboard/SpecialOffers/SpecialOffers";
import store from "../redux/store";
import ProfileUser from "../Pages/ProfileUser/ProfileUser";
import Offer from "../Pages/Offer/Offer";
import MyOrder from "../Pages/MyOrder/MyOrder";
import AddSlider from "../Dashboard/BannerSlider/AddSlider";
import Categories from "../Dashboard/Categories/Categories";
import Articles from "../Pages/Articles/Articles";
import TipsControl from "../Dashboard/Tips/TipsControl";
import ArticlesControl from "../Dashboard/ArticlesControl/ArticlesControl";
import Article from "../Pages/Article/Article";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "WhiteFriday", element: <WhiteFriday /> },
      { path: "About", element: <About /> },
      { path: "Articles", element: <Articles /> },
      { path: "Articles/:id", element: <Article /> },
      { path: "Chart", element: <Chart /> },
      { path: "Contact", element: <Contact /> },
      { path: "Favourite", element: <Favourite /> },
      { path: "Products", element: <Products /> },
      { path: "profile", element: <ProfileUser /> },
      { path: "MyOrder", element: <MyOrder /> },
      { path: "Chart", element: <Chart /> },
      { path: "Products/:id", element: <Product /> },
      { path: "offer/:id", element: <Offer /> },
    ],
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    loader: (e) => {
      if (store.getState().auth.userData?.role !== "admin") {
        window.location.href = "/";
      }
      return null;
    },
    children: [
      { index: true, element: <UsersControl /> },
      { path: "users", element: <UsersControl /> },
      { path: "category", element: <Categories /> },
      { path: "products", element: <ProductsControl /> },
      { path: "orders", element: <OrdersControl /> },
      { path: "articles", element: <ArticlesControl /> },
      { path: "tips", element: <TipsControl /> },
      { path: "specialOffers", element: <SpecialOffers /> },
      { path: "AddSlider", element: <AddSlider /> },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "SignUp",
    element: <SignUp />,
  },
]);
