import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import {
  Button,
  DatePicker,
  Image,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Upload,
} from "antd";

import {
  useEditCategoryMutation,
  useEditTipMutation,
  useEditUserAddressMutation,
  useGetAllUserAddressesQuery,
  useGetFullCategoryDetailsQuery,
  useGetFullTipDetailsQuery,
} from "../../redux/Service/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";

const EditAddress = ({
  setOpenEditModal,
  openEditModal,
  addressIdForEdit,
  rerenderEditKey,
}) => {
  const [modalKey, setModalKey] = useState(0);

  const [build, setBuild] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [government, setGovernment] = useState("");

  const { userData } = useSelector((st) => st.auth);

  const {
    data: allAddressesData,
    isError: isAllAddressesError,
    isLoading: isAllAddressesLoading,
    error: allAddressesError,
  } = useGetAllUserAddressesQuery();

  // const userAddress =
  //   allAddressesData?.data?.length > 0 ? allAddressesData?.data[0] : null;

  const userAddress =
    userData?.addresses?.length > 0 ? userData?.addresses[0] : null;

  useEffect(() => {
    setBuild(userAddress?.build_name);
    setStreet(userAddress?.street_name);
    setCity(userAddress?.city);
    setGovernment(userAddress?.government);
  }, [userAddress, rerenderEditKey]);

  const [
    editAddress,
    { isLoading: isEditLoading, isError: isEditError, error },
  ] = useEditUserAddressMutation();

  const handleEditAddress = async () => {
    if (!build) {
      message.error("Please add the build number");
      return;
    }
    if (!street) {
      message.error("Please add the street name");
      return;
    }
    if (!city) {
      message.error("Please add the city");
      return;
    }
    if (!government) {
      message.error("Please add the government");
      return;
    }

    const addressBody = {
      build_name: build,
      street_name: street,
      city: city,
      government: government,
      landmark: "landmark",
    };

    try {
      const res = await editAddress({
        id: addressIdForEdit,
        addressData: addressBody,
      });

      setModalKey((prev) => prev + 1);

      res.data.message
        ? message.info(res.data.message)
        : message.success("Address added successfully");
      setOpenEditModal(false);
    } catch (error) {
      console.error("Error adding Address:", error);
      message.error("Error adding Address. Please try again.");
    }
  };

  return (
    <Modal
      key={modalKey}
      title="Edit Address"
      centered
      open={openEditModal}
      onCancel={() => setOpenEditModal(false)}
      width={500}
      footer
    >
      {isAllAddressesLoading && (
        <p>
          <Spin size="small" style={{ marginRight: "0.2rem" }} />
          loading details...
        </p>
      )}
      {isAllAddressesError && (
        <p className="text-danger">Something went error</p>
      )}
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
          size="large"
        >
          <Row className="g-2">
            <Col className="gap-3 d-flex flex-column">
              <Input
                placeholder="build number"
                value={build}
                onChange={(e) => setBuild(e.target.value)}
              />

              <Input
                placeholder="Street name"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
              <Input
                placeholder="City name"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <Input
                placeholder="government"
                value={government}
                onChange={(e) => setGovernment(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="g-2">
            <Button
              type="primary"
              onClick={handleEditAddress}
              loading={isEditLoading}
            >
              edit
            </Button>
          </Row>
        </Space>
      </Space>
    </Modal>
  );
};

export default EditAddress;
