import { Col, Container, Row } from "react-bootstrap";
import { images } from "../../constants";
import "./About.css";
import AOS from "aos";
import { Store } from "../../Sections";
import { language } from "../../language/Language";
AOS.init({
  once: true,
});

const About = () => {
  return (
    <>
      <section className="about">
        <section className="about_img"></section>
        <Store />
        {/* <main className="about_info">
          <Container>
            <div className="about_story" data-aos="fade-up">
              <Row className="justify-content-between">
                <Col xs={12} lg={6} className="mb-5 mb-lg-0">
                  <div className="about_story_text">
                    <h2>{language === "ar" ? "قصتنا" : "Our Story"}</h2>
                    <p>
                      {language === "ar" ? `Old Spice هي ماركة كان يرتديها والدي في السبعينيات والثمانينيات، ولكن
                      تعود العلامة التجارية نفسها إلى الثلاثينيات. مؤسس
                      من شركة شولتون، ويليام لايتفوت شولتز، الذي
                      صنعت المنتج، واختارت موضوعًا بحريًا للمنتج
                      ماركة. على مر السنين استخدمت العلامة التجارية الاستعمارية
                      السفن الشراعية كحافز. كيف يفعل ذلك؟ حسنا انها
                      مكتوبة بشكل جيد (وقابلة للاقتباس جدًا)، مملوكة الآن لشركة Procter &
                      غامبل، تلك السفن الشراعية قد لا تكون موضوعًا مركزيًا،
                      لكنهم ما زالوا موجودين.` : `Old Spice is a brand my Dad wore in the 1970s and 80s, but
                      the brand itself goes way back to the 1930s. The founder
                      of the Shulton Company, William Lightfoot Schultz, who
                      manufactured the product, chose a nautical theme for the
                      brand. Throughout the years the brand has used colonial
                      sailing ships as a motif. How does it do that? Well, it’s
                      well-written (and very quotable), Now owned by Procter &
                      Gamble, those sailing ships may not be a central theme,
                      but they are still present.`}
                    </p>
                    <p>
                      {language === "ar" ? `التحدي الذي يواجه Old Spice هو البقاء على صلة بـ a
                      جيل الشباب، وهذا هو المكان الذي يوجد فيه فيدن + كينيدي
                      إعلان، تأتي حملة الرجل الذي يمكن لرجلك أن يشم رائحته
                      يلعب. الفرضية الأساسية ليست أن شركة Old Spice ستفعل ذلك
                      حولي رجلك إلى الرجل المثالي، لكن هذا قديم
                      التوابل يمكن أن تجعل رائحة رجلك مثل الرجل المثالي.
                      "كل شيء ممكن عندما تكون رائحتك كرجل وليس كرجل
                      سيدة.` : `The challenge to Old Spice is staying relevant to a
                      younger generation, and this is where the Wieden+Kennedy
                      ad, The Man Your Man Could Smell Like campaign comes into
                      play. The basic premise is not that Old Spice will
                      transform your man into the perfect man, but that Old
                      Spice can make your man smell like the perfect man.
                      “Anything is possible when you smell like a man and not a
                      lady.”`}
                    </p>
                    <p>
                      {language === "ar" ? `أي أسئلة؟ أخبرنا في المتجر في الطابق التاسع، X فيصل
                      ش، الجيزة، مصر أو اتصل بنا على (+2) 0123456789` : `Any questions? Let us know in store at 9th floor, X Faisal
                      St, Giza, Egypt or call us on (+2) 0123456789`}
                    </p>
                  </div>
                </Col>
                <Col xs={12} lg={5} className="mb-5 mb-lg-0">
                  <div className="about_story_image">
                    <img src={images.about1} alt="story img" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="about_mission mb-3" data-aos="fade-up">
              <Row className="justify-content-between">
                <Col xs={12} lg={5} className="mb-5 mb-lg-0">
                  <div className="about_mission_image">
                    <img src={images.about2} alt="mission img" />
                  </div>
                </Col>
                <Col xs={12} lg={6} className="mb-5 mb-lg-0">
                  <div className="about_mission_text">
                    <h2>{language === "ar" ? "مهمتنا" : "Our Mission"}</h2>
                    <p>
                      {language === "ar" ? `مهمة eShop هي التفاني في تحقيق أعلى مستويات الجودة
                      خدمة العملاء المقدمة مع الشعور بالدفء،
                      الود والفخر الفردي وروح الشركة. نحن
                      إشعال الفرص من خلال تحريك العالم. بشر
                      المعاناة في مواجهة حالات الطوارئ من خلال تعبئة
                      قوة المتطوعين وكرم المانحين. ملكنا
                      المهمة هي إطلاق العنان لإمكانات الإبداع البشري -
                      من خلال منح الفرصة لمليون فنان مبدع
                      يعيشون على فنهم ويتيح لمليارات المعجبين فرصة ذلك
                      استمتع بها واستلهم منها.` : `The mission of eShop is dedication to the highest quality
                      of customer service delivered with a sense of warmth,
                      friendliness, individual pride, and company spirit. We
                      ignite opportunity by setting the world in motion. human
                      suffering in the face of emergencies by mobilizing the
                      power of volunteers and the generosity of donors. Our
                      mission is to unlock the potential of human creativity —
                      by giving a million creative artists the opportunity to
                      live off their art and billions of fans the opportunity to
                      enjoy and be inspired by it.`}
                    </p>
                    <p>
                      {language === "ar" ? `الإبداع هو مجرد ربط الأشياء. عندما تسأل
                      الأشخاص المبدعون كيف فعلوا شيئًا ما، يشعرون قليلاً
                      مذنبون لأنهم لم يفعلوا ذلك حقًا، بل رأوا فقط
                      شئ ما. بدا واضحا لهم بعد حين.` : ` Creativity is just connecting things. When you ask
                      creative people how they did something, they feel a little
                      guilty because they didn't really do it, they just saw
                      something. It seemed obvious to them after a while.`}
                      <br />
                      <br /> {language === "ar" ? "- ستيف جوبز" : "- Steve Job’s"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </main> */}
      </section>
    </>
  );
};

export default About;
