import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay } from "swiper";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemtofav, deleteFavItem } from "../../redux/action";
import "./featured-products.css";
import {
  useAddOrRemoveFavouriteProductMutation,
  useGetAllSpecialProductsQuery,
  useGetFavouritesProductsQuery,
} from "../../redux/Service/api";
import Loading from "../../components/utils/Loading";
import { Spin } from "antd";
import { language } from "../../language/Language";
import ServerError from "../../components/utils/ServerError";

const FeaturedProducts = () => {
  const route = useNavigate();
  // const favItems = useSelector((state) => state.handlechart.fav);
  const dispatch = useDispatch();

  const {
    data: allSpecialProductsData,
    isError: isSpecialProdsError,
    isLoading: isSpecialProdsLoading,
    error: specialprodsError,
  } = useGetAllSpecialProductsQuery();
  const allSpecialProducts = allSpecialProductsData?.data ?? null;

  const [
    addOrDeleteProduct,
    {
      isLoading: addOrDelIsLoading,
      isError: addOrDelIsError,
      error: addOrDelError,
    },
  ] = useAddOrRemoveFavouriteProductMutation();
  const {
    data: favItemsData,
    isError: isFavError,
    isLoading: isFavLoading,
    error: favError,
  } = useGetFavouritesProductsQuery();

  const favItems = favItemsData?.data ?? null;

  const handlepropa = (e) => {
    e.stopPropagation();
  };
  const handlefav = (ele) => {
    addOrDeleteProduct({ item_id: ele.id });
  };
  if (isSpecialProdsError) {
    return <ServerError />;
  }
  return (
    <section
      className="Trending section_padding"
      data-aos={"fade-up"}
      style={{ position: "relative" }}
    >
      {allSpecialProducts?.length > 0 && (
        <h2 className="main_heading">
          {language === "ar" ? "المنتجات الخاصة" : "Special Products"}
        </h2>
      )}
      {isSpecialProdsLoading && <Loading />}

      <Container>
        <Swiper
          slidesPerView={2}
          spaceBetween={10}
          slidesPerGroup={1}
          initialSlide={2}
          loop={true}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          loopFillGroupWithBlank={true}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {allSpecialProducts?.map((sp) => (
            <SwiperSlide key={sp.id}>
              <figure onClick={() => route(`/Products/${sp.id}`)}>
                <div
                  className="trend_image_hold"
                  style={{ position: "relative" }}
                >
                  {/* {addOrDelIsLoading && <Loading />} */}
                  <img src={sp.icon} alt="trending" />
                  {/* <ul className="trend_image_option" onClick={handlepropa}>
                    <li
                      onClick={() => handlefav(sp)}
                      className={favItems
                        ?.map((el) =>
                          el.item_id === sp.id && !el.is_offer ? "favicon" : ""
                        )
                        .join("")}
                    >
                      {addOrDelIsLoading && <Spin />}
                    </li>
                  </ul> */}
                </div>
                <figcaption>
                  <p className="image_desc">{sp.name}</p>
                  <div className="price">
                    <span
                      className={
                        sp.discount !== 0 ? "oldprice" : "currentPrice"
                      }
                    >
                      {sp.price} {sp.is_dollar == 1 ? "$" : "EGP"}
                    </span>
                    {sp.discount !== 0 && (
                      <span className="currentPrice">EGP {sp.discount}</span>
                    )}
                  </div>
                  <ul className="trend_image_option" onClick={handlepropa}>
                    <li
                      onClick={() => handlefav(sp)}
                      className={favItems
                        ?.map((el) =>
                          el.item_id === sp.id && !el.is_offer ? "favicon" : ""
                        )
                        .join("")}
                    >
                      {addOrDelIsLoading && <Spin />}
                    </li>
                  </ul>
                </figcaption>
              </figure>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default FeaturedProducts;
