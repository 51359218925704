import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "aos/dist/aos.css";

import { RouterProvider, useLocation } from "react-router-dom";
import { router } from "./router";

import "./App.css";

import AOS from "aos";
AOS.init({
  once: true,
});

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
